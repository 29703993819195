<template>
  <v-container>
    <v-card class="px-5 py-3">
      <v-tabs>
        <v-tab @click="deleted = false">Активные</v-tab>
        <v-tab @click="deleted = true">Не активные</v-tab>
      </v-tabs>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :single-select="true"
        :items-per-page="10"
        loading-text="Идёт загрузка данных..."
        item-key="id"
        :loading="loading"
        :options.sync="options"
        :footer-props="footerProps"
        style="cursor: pointer"
      >
        <template v-slot:[`item.index`]="{ item }">
          {{ items.indexOf(item) + 1 }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="!deleted" small class="mr-2" @click="editName(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="!deleted"
            small
            color="red"
            class="mr-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="deleted"
            small
            class="mr-2"
            color="success"
            @click="deleteItem(item)"
          >
            mdi-power
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" class="ma-3" @click="create"> Добавить </v-btn>
    </v-card>
    <v-dialog v-model="dialog" max-width="1000">
      <v-card>
        <v-card-title class="headline">
          Добавление нового типа активного отдыха
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="foodName.name"
            label="Введите название"
            dense
          />
          <br />
          <v-text-field v-model="foodName.slug" label="Введите slug" dense />
        </v-card-text>
        <v-card-text>
          <div ref="editorWrapperDescr">
            <editor
              v-if="dialog"
              apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
              v-model="foodName.header_description"
              :init="init"
              :key="count"
            />
          </div>

          <div ref="editorWrapperDescr1">
            <editor
              v-if="dialog"
              apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
              v-model="foodName.description"
              :init="init"
              :key="count"
            />
          </div>
        </v-card-text>
        <v-row class="mx-2">
          <v-col class="pt-0 pb-0" cols="4">
            <v-file-input
              v-model="singleImage"
              label="Главное фото"
              prepend-icon="mdi-camera"
              show-size
              chips
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <v-file-input
              ref="multiImages"
              v-model="multiImages"
              label="Фото"
              prepend-icon="mdi-camera"
              multiple
              counter
              show-size
              chips
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              (dialog = false),
                (foodName = {}),
                (singleImage = null),
                (multiImages = [])
            "
          >
            Назад
          </v-btn>
          <v-btn color="primary" text @click="saveFood" :disabled="disabledBtn">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subplacedialog" max-width="1000" persistent>
      <v-card>
        <v-card-title class="headline">
          Редактировать тип активного отдыха
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="foodName.name"
            label="Введите новое название"
            dense
          />
          <br />
          <v-text-field v-model="foodName.slug" label="Введите slug" dense />
        </v-card-text>
        <v-card-text>
          <div ref="editorWrapperDescr">
            <editor
              v-if="subplacedialog"
              apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
              v-model="foodName.header_description"
              :init="init"
              :key="count"
            />
          </div>

          <div ref="editorWrapperDescr1" style="margin-top: 20px">
            <editor
              apiKey="469ystiyxmnamzivw078tc8ejroxgrdpnersv5ncjsqcuij2"
              v-model="foodName.description"
              :init="init"
              v-if="subplacedialog"
              :key="count"
            />
          </div>
        </v-card-text>
        <v-row class="mx-2">
          <v-col class="pt-0 pb-0" cols="4">
            <v-file-input
              v-model="singleImage"
              label="Главное фото"
              prepend-icon="mdi-camera"
              show-size
              chips
            />
          </v-col>
          <v-col class="pt-0 pb-0" cols="8">
            <v-file-input
              ref="multiImages"
              v-model="multiImages"
              label="Фото"
              prepend-icon="mdi-camera"
              multiple
              counter
              show-size
              chips
            />
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-col class="pt-0 pb-0 ml-5" cols="12">
          <v-row class="mb-5" align="center" v-if="foodName.main_image">
            <img :src="foodName.main_image" height="300" />
            <v-btn icon @click="deleteMainImage">
              <v-icon small color="red darken-2"> mdi-delete </v-icon>
            </v-btn>
          </v-row>

          <div v-for="(image, index) in foodName.image_list" :key="index">
            <v-row class="mb-5" align="center" v-if="image.is_main">
              <img :src="image.image" height="300" />
              <v-btn icon @click="deleteImage(index, image.id)">
                <v-icon small color="red darken-2"> mdi-delete </v-icon>
              </v-btn>
            </v-row>
            <v-row class="mb-4" align="center" v-if="!image.is_main">
              <img :src="image.image" height="200" />
              <v-btn icon @click="deleteImage(index, image.id)">
                <v-icon small color="red darken-2"> mdi-delete </v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              (subplacedialog = false),
                (foodName = {}),
                (singleImage = null),
                (multiImages = []),
                (overlay = false)
            "
          >
            Отмена
          </v-btn>
          <v-btn
            color="primary"
            @click="newFoodName"
            text
            :disabled="disabledBtn"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import Editor from "@tinymce/tinymce-vue";
import { LeisureApiUrls } from "@/services/leisureRequests/leisure.api.js";

export default {
  name: "Location",
  components: {
    editor: Editor
  },
  data: () => ({
    count: 1,
    init: {
      height: 300,
      menubar: false,
      menu: {
        edit: { title: "Edit", items: "undo redo | selectall" }
      },
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount"
      ],
      toolbar:
        "undo redo | fontselect | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help"
    },
    deleted: false,
    disabledBtn: false,
    loading: false,
    foodName: {
      id: null,
      name: null,
      description: null,
      header_description: null
    },
    footerProps: {
      "items-per-page-options": [
        {
          text: "10",
          value: 10
        },
        {
          text: "20",
          value: 20
        },
        {
          text: "30",
          value: 30
        },
        {
          text: "Все",
          value: 99999999999
        }
      ]
    },
    options: {},
    firstWatcherTrigger: false,
    multiImages: [],
    singleImage: null,
    liveDescription: "",
    overlay: false,
    dialog: false,
    subplacedialog: false,
    selected: [],
    checkDeleted: false,
    headers: [
      { text: "№", value: "index", class: "table-title" },
      {
        text: "Название",
        value: "name",
        sortable: false,
        class: "table-title"
      },
      {
        text: "Редактировать",
        value: "actions",
        sortable: false,
        class: "table-title"
      }
    ],
    items: []
  }),
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadRow();
      },
      deep: true
    },
    dialog() {
      if (this.dialog) {
        this.foodName = {
          id: null,
          name: null
        };
      }
    },
    deleted() {
      this.loadRow();
    }
  },
  created() {
    this.loadRow();
  },
  methods: {
    async deleteItem(item) {
      await LeisureApiUrls.deleteLeisureType(item.id);
      this.loadRow();
      if (!this.deleted) toast.success("Успешно удалено!");
      else toast.success("Успешно восстановлено!");
    },
    deleteMainImage() {
      this.foodName.main_image = null;
      this.checkDeleted = true;
    },
    async deleteImage(index, idImage) {
      await LeisureApiUrls.deleteLeisureImage(idImage);
      this.foodName.image_list.splice(index, 1);
    },
    async editName(item) {
      this.foodName = await LeisureApiUrls.getLeisureType(item.id);
      if (!this.foodName.main_image) this.checkDeleted = true;
      this.subplacedialog = true;
      this.overlay = false;
      this.count++;
      this.$forceUpdate();
    },
    async newFoodName() {
      if (this.checkDeleted && !this.singleImage) {
        toast.error("Загрузите главное фото!");
        return;
      }
      this.overlay = true;
      this.disabledBtn = true;
      try {
        let resp = await LeisureApiUrls.setLeisureType(this.foodName.id, {
          ...this.foodName
        });
        console.log(resp, this.checkDeleted, this.singleImage);
        if (this.checkDeleted && this.singleImage) {
          var formData = new FormData();
          formData.append(
            "main_image",
            this.singleImage,
            this.singleImage.fileName
          );
          await LeisureApiUrls.setLeisureMainImage(resp.id, formData);
          this.singleImage = null;
        }
        if (this.multiImages.length > 0) {
          this.multiImages.forEach(async function(e) {
            var formData = new FormData();
            formData.append("image", e, e.fileName);
            formData.append("leisure_type", resp.id);
            await LeisureApiUrls.createLeisureImage(formData);
          });
          this.multiImages = [];
        }
      } finally {
        this.foodName = {};
        this.overlay = false;
        this.subplacedialog = false;
        this.dialog = false;
        this.disabledBtn = false;
        this.checkDeleted = false;
        this.loadRow();
      }
    },
    async saveFood() {
      if (!this.foodName.name) {
        toast.error("Напишите название!");
      }
      if (!this.foodName.slug) toast.error("Напишите slug!");
      if (this.foodName.slug && this.foodName.name) {
        this.disabledBtn = true;
        this.overlay = true;
        try {
          let resp = await LeisureApiUrls.createLeisureType({
            ...this.foodName
          });

          if (this.singleImage) {
            var formData = new FormData();
            formData.append(
              "main_image",
              this.singleImage,
              this.singleImage.fileName
            );
            await LeisureApiUrls.setLeisureMainImage(resp.id, formData);
            this.singleImage = null;
          }

          if (this.multiImages.length > 0) {
            this.multiImages.forEach(async function(e) {
              var formData = new FormData();
              formData.append("image", e, e.fileName);
              formData.append("leisure_type", resp.id);
              await LeisureApiUrls.createLeisureImage(formData);
            });
            this.multiImages = [];
          }
        } finally {
          this.disabledBtn = false;
          this.dialog = false;
          this.overlay = false;
          this.foodName = {};
          this.loadRow();
        }
      }
    },
    async loadRow() {
      this.loading = true;
      let params = {
        deleted: this.deleted,
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      let response = await LeisureApiUrls.getLeisureTypes(params);
      this.items = response.results;
      this.loading = false;
    },
    create() {
      this.dialog = true;
      this.count++;
      this.$forceUpdate();
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border: 1px solid black;
}
</style>
