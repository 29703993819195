import { render, staticRenderFns } from "./leisureType.vue?vue&type=template&id=78ef5b90&scoped=true"
import script from "./leisureType.vue?vue&type=script&lang=js"
export * from "./leisureType.vue?vue&type=script&lang=js"
import style0 from "./leisureType.vue?vue&type=style&index=0&id=78ef5b90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ef5b90",
  null
  
)

export default component.exports